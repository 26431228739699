<template>
    <div class="PurchaseRefundCreate" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-form
            @submit.native.prevent
            :inline="true"
            :model="form"
            size="small"
            label-width="100px"
            :rules="rules"
            ref="createForm"
        >
            <el-card shadow="never" style="margin-top: 8px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="采购订单号" prop="purchaseApplyCode">
                            <el-input
                                placeholder="采购订单号"
                                v-model.trim="form.purchaseApplyCode"
                                @change="validateAndSelectPurchaseOrder"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="退货机构" prop="deptCode">
                            <el-select v-model="form.deptCode" filterable>
                                <el-option
                                    v-for="item in depts"
                                    :label="item.name"
                                    :value="item.code"
                                    :key="item.code"
                                    :disabled="!item.selected"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="配送中心" prop="deptCode">
                            <el-input v-model="form.repoCode" readonly style="display: none" />
                            <el-input v-model="repoName" readonly />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="客户" prop="customer">
                            <el-input v-model.trim="form.customer" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="客户电话" prop="customerMobile">
                            <el-input v-model.trim="form.customerMobile" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="客户地址" prop="customerAddress">
                            <el-input v-model.trim="form.customerAddress" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="创建人">
                            <el-input v-model="creator" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建日期">
                            <el-input :value="createTime" readonly />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
            <el-card shadow="never" style="margin-top: 8px; text-align: left">
                <el-button type="primary" @click="handleSave" size="small" :disabled="disableSaveButton"
                    >保存</el-button
                >
                <el-button type="primary" @click="validateAndSelectPurchaseOrder" size="small">查询</el-button>
            </el-card>
            <el-card shadow="never" style="margin-top: 8px" align="left">
                <el-table
                    border
                    stripe
                    style="width: 100%"
                    :data="form.detailParams"
                    size="mini"
                    :highlight-current-row="true"
                    max-height="450"
                >
                    <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                    <el-table-column label="商品名称" width="180" fixed="left">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).name }}
                        </template>
                    </el-table-column>
                    <el-table-column label="条码" width="130">
                        <template slot-scope="scope">
                            <div v-for="b in skuByGoodsCode(scope.row.goodsCode).bars" :key="b">{{ b }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="规格" width="100">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).specs }}
                        </template>
                    </el-table-column>
                    <el-table-column label="类目" width="100">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).category }}
                        </template>
                    </el-table-column>
                    <el-table-column label="单位" width="80">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).unit }}
                        </template>
                    </el-table-column>
                    <el-table-column label="品牌" width="100">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).brand }}
                        </template>
                    </el-table-column>
                    <el-table-column label="退货价" width="140" v-if="showInPrice()">
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'detailParams.' + scope.$index + '.showPrices'"
                                :rules="rules.showPrices"
                            >
                                <ef-price-input
                                    v-model="scope.row.showPrices"
                                    :precision="2"
                                    @change="computeThenChangeCountAndMoney(scope.row)"
                                />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="可退数量" width="80" prop="purchaseCounts" />
                    <template v-if="enabledBigUnit">
                        <el-table-column label="包装因子">
                            <template slot-scope="scope">
                                <el-form-item
                                    :prop="'detailParams.' + scope.$index + '.packFactors'"
                                    :rules="rules.packFactors"
                                >
                                    {{ scope.row.packFactors }}
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column label="退货箱数" min-width="120">
                            <template slot-scope="scope">
                                <el-form-item
                                    :prop="'detailParams.' + scope.$index + '.bigUnitCounts'"
                                    :rules="rules.bigUnitCounts"
                                >
                                    <ef-stock-biz-count-input
                                        v-model="scope.row.bigUnitCounts"
                                        @change="computeThenChangeCountAndMoney(scope.row)"
                                    />
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column label="散装数量" min-width="120">
                            <template slot-scope="scope">
                                <el-form-item
                                    :prop="'detailParams.' + scope.$index + '.bulkCounts'"
                                    :rules="rules.bigUnitCounts"
                                >
                                    <ef-stock-biz-count-input
                                        v-model="scope.row.bulkCounts"
                                        @change="computeThenChangeCountAndMoney(scope.row)"
                                    />
                                </el-form-item>
                            </template>
                        </el-table-column>
                    </template>
                    <el-table-column label="退货数量" width="190">
                        <template slot-scope="scope">
                            <el-form-item :prop="'detailParams.' + scope.$index + '.counts'" :rules="rules.counts">
                                <ef-stock-biz-count-input
                                    v-if="enabledBigUnit"
                                    v-model="scope.row.counts"
                                    :disabled="true"
                                />
                                <ef-stock-biz-count-input
                                    v-else
                                    v-model="scope.row.counts"
                                    @change="computeThenChangeCountAndMoney(scope.row)"
                                />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="退货金额" width="90" v-if="showInPrice()">
                        <template slot-scope="scope"
                            ><span>
                                <span>{{ scope.row.purchaseRefundShowMoney }}</span>
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column label="可退赠品数量" width="80" prop="purchaseGiftCounts" />
                    <el-table-column label="退货赠品数量" width="220">
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'detailParams.' + scope.$index + '.giftCounts'"
                                :rules="rules.giftCounts"
                            >
                                <ef-stock-biz-count-input
                                    v-model="scope.row.giftCounts"
                                    @change="computeThenChangeCountAndMoney(scope.row)"
                                />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="退货总数量" width="190">
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'detailParams.' + scope.$index + '.totalCounts'"
                                :rules="rules.totalCounts"
                            >
                                <ef-stock-biz-count-input v-model="scope.row.totalCounts" :disabled="true" />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" min-width="80" header-align="center">
                        <template slot-scope="scope">
                            <el-button size="mini" type="danger" @click="handleDelete(scope.$index)"> 删除 </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>
        </el-form>
    </div>
</template>

<script>
import UrlUtils from 'js/UrlUtils';
import Util from 'js/Util';
import MoneyUtils from 'js/MoneyUtils';
import { DeptTypeEnum } from 'js/DeptCommon';
import GoodsCommon from 'js/GoodsCommon';
import { get } from 'request/http';
import StockBizCommon, { packFactor, PropertyConverter } from 'js/StockBizCommon';
import {
    deptCodeValidateRule,
    goodsCodeValidateRule,
    mobileValidateRule,
    packFactorsValidateRule,
    pricesValidateRule,
} from 'js/validate/ValidateCommonRule';
import { countGreaterThanZeroValidateRule, countValidateRule } from 'js/validate/ValidateStockBizRule';
import EfStockBizCountInput from 'components/EfStockBizCountInput';
import EfPriceInput from 'components/EfPriceInput';

export default {
    name: 'PurchaseRefundCreate',
    components: { EfStockBizCountInput, EfPriceInput },
    data() {
        return {
            createTime: Util.nowFormatDate(),
            creator: this.$store.state.session.name,
            repoName: '',
            selectedDept: null,
            form: {
                deptCode: '',
                repoCode: '',
                purchaseApplyCode: '',
                customer: '',
                customerMobile: '',
                customerAddress: '',
                detailParams: [],
            },
            //所选商品详情列表
            goodsDetailMap: new Map(),
            enabledBigUnit: false,
            url: {
                queryRelationPurchaseApplyCode: '/biz/purchaseSaleRelation/list',
                queryPurchaseIn: '/purchase/in/info_c/',
                save: '/purchase/refund/apply/createExtend/',
            },
            depts: [],
            rules: {
                purchaseApplyCode: {
                    required: true,
                    message: '请填写采购订单号',
                    trigger: ['blur'],
                },
                repoCode: deptCodeValidateRule(),
                deptName: {
                    required: false,
                    pattern: /^[a-zA-Z\u4e00-\u9fa5]{2,30}$/,
                    message: '至少两个中文汉字或字母',
                    trigger: ['blur'],
                },
                customer: {
                    required: false,
                    pattern: /^[a-zA-Z\u4e00-\u9fa5]{2,10}$/,
                    message: '至少两个中文汉字或字母',
                    trigger: ['blur'],
                },
                customerMobile: mobileValidateRule(false),
                customerAddress: {
                    required: false,
                    pattern: /^[a-zA-Z\u4e00-\u9fa50-9]{2,30}$/,
                    message: '请正确输入客户地址',
                    trigger: ['blur'],
                },
                goodsCode: goodsCodeValidateRule(),
                packFactors: packFactorsValidateRule(),
                showPrices: pricesValidateRule(),
                counts: [
                    countValidateRule(
                        () => this.form.detailParams || [],
                        (goodsCode) => this.skuByGoodsCode(goodsCode)
                    ),
                    {
                        validator: (rule, count, callback) => {
                            rule.fullField.match(/\.(\d*)\./);
                            const idx = Number(RegExp.$1);
                            const purchaseCounts = this.form.detailParams[idx].purchaseCounts;
                            count <= purchaseCounts ? callback() : callback(new Error('退货数量不能超过采购数量'));
                        },
                        trigger: ['change', 'blur'],
                    },
                ],
                giftCounts: [
                    countValidateRule(
                        () => this.form.detailParams || [],
                        (goodsCode) => this.skuByGoodsCode(goodsCode)
                    ),
                    {
                        validator: (rule, count, callback) => {
                            rule.fullField.match(/\.(\d*)\./);
                            const idx = Number(RegExp.$1);
                            const purchaseGiftCounts = this.form.detailParams[idx].purchaseGiftCounts;
                            count <= purchaseGiftCounts ? callback() : callback(new Error('退货数量不能超过采购数量'));
                        },
                        trigger: ['change', 'blur'],
                    },
                ],
                bigUnitCounts: [
                    countValidateRule(
                        () => this.form.detailParams || [],
                        (goodsCode) => this.skuByGoodsCode(goodsCode)
                    ),
                ],
                totalCounts: [countGreaterThanZeroValidateRule()],
            },
        };
    },
    mounted() {
        this.$efApi.deptApi.managedDeptsByDeptType(DeptTypeEnum.STORE).then((rst) => {
            this.depts = rst;
            this.depts.forEach((e) => {
                //设置选中属性
                e.selected = false;
            });
        });
    },
    computed: {
        //禁用/启用按钮
        disableSaveButton() {
            return this.form.detailParams == null || this.form.detailParams.length === 0;
        },
    },
    filters: {
        skuTypeDesc(type) {
            return GoodsCommon.skuTypeDesc(type);
        },
    },
    methods: {
        detailParamPropertyArrForShow() {
            const rst = [
                'goodsCode',
                'counts',
                'giftCounts',
                new PropertyConverter('prices', 'showPrices', (price) => {
                    return MoneyUtils.moneyToYuan(price);
                }),
                new PropertyConverter('counts', 'purchaseCounts'),
                new PropertyConverter('giftCounts', 'purchaseGiftCounts'),
            ];
            if (this.enabledBigUnit) {
                rst.push(
                    new PropertyConverter(null, 'packFactors', (packFactors, detailParam) => {
                        //获取门店商品的包装因子
                        return detailParam.goodsDetail[packFactor] || 1;
                    }),
                    new PropertyConverter(null, 'bigUnitCounts', (bigUnitCounts, detailParam) => {
                        return Math.floor(detailParam.counts / detailParam.packFactors);
                    }),
                    new PropertyConverter(null, 'bulkCounts', (bulkCounts, detailParam) => {
                        return detailParam.counts % detailParam.packFactors;
                    })
                );
            }
            rst.push(
                new PropertyConverter(null, 'purchaseRefundShowMoney', (purchaseRefundShowMoney, detailParam) => {
                    return this.computeShowMoney(detailParam);
                }),
                new PropertyConverter(null, 'totalCounts', (totalCounts, detailParam) => {
                    return this.decimal(detailParam.counts).add(detailParam.giftCounts).toNumber();
                })
            );
            return rst;
        },
        computeThenChangeCountAndMoney(row) {
            this.$nextTick(() => {
                //计算数量和金额
                let counts;
                if (this.enabledBigUnit) {
                    //启用大单位
                    let bulkCounts = row.bulkCounts || 0;
                    const packFactors = row.packFactors || 1;
                    let bigUnitCounts = row.bigUnitCounts || 0;
                    const bigUnitCountsForBulkCounts = Math.floor(bulkCounts / packFactors);
                    if (bigUnitCountsForBulkCounts > 0) {
                        bigUnitCounts = this.decimal(bigUnitCounts).add(bigUnitCountsForBulkCounts).toNumber();
                        bulkCounts = bulkCounts % packFactors;
                        row.bulkCounts = bulkCounts;
                        row.bigUnitCounts = bigUnitCounts;
                    }
                    counts = this.computeCount(row);
                } else {
                    counts = row.counts || 0;
                }
                row.counts = counts;
                row.purchaseRefundShowMoney = this.computeShowMoney(row);
                //计算总数量
                row.totalCounts = this.decimal(counts)
                    .add(row.giftCounts || 0)
                    .toNumber();
            });
        },
        computeCount(detailParam) {
            let counts;
            if (this.enabledBigUnit) {
                //启用大单位
                counts = this.decimal(detailParam.packFactors || 1)
                    .mul(detailParam.bigUnitCounts || 0)
                    .add(detailParam.bulkCounts || 0)
                    .toNumber();
            } else {
                //未启用大单位
                counts = detailParam.counts || 0;
            }
            return counts;
        },
        computeShowMoney(detailParam) {
            return this.decimal(detailParam.counts || this.computeCount(detailParam))
                .mul(detailParam.showPrices || 0)
                .toNumber();
        },

        async validateAndSelectPurchaseOrder() {
            //置空，清除数据
            this.form.deptCode = null;
            this.form.repoCode = null;
            this.repoName = null;
            this.form.detailParams = [];
            if (!Util.isEmpty(this.selectedDept)) {
                this.selectedDept.selected = false;
            }
            this.selectedDept = null;

            if (Util.isEmpty(this.form.purchaseApplyCode)) {
                this.$message.error('请输入采购订单号');
                return;
            }
            const rst =
                (await get(this.url.queryRelationPurchaseApplyCode, {
                    purchaseApplyCode: this.form.purchaseApplyCode,
                })) || [];
            const purchaseInCode = rst.length >= 1 ? rst[0].purchaseInCode : null;
            if (Util.isEmpty(purchaseInCode)) {
                this.$message.error('请检查采购订单是否存在或已入库');
                return;
            }
            const purchaseInBiz = await get(`${this.url.queryPurchaseIn}/${purchaseInCode}`);
            const matchedDept = this.depts.find((e) => {
                return !Util.isEmpty(purchaseInBiz.deptCode) && e.code === purchaseInBiz.deptCode;
            });
            if (Util.isEmpty(matchedDept)) {
                this.$message.error('采购订单无法匹配上当前退货机构列表');
                return;
            }
            //将退货机构列表中匹配上的机构设置为可选且自动选中
            matchedDept.selected = true;

            this.selectedDept = matchedDept;
            this.form.deptCode = matchedDept.code;
            this.form.repoCode = purchaseInBiz.repoCode;
            this.repoName = purchaseInBiz.repoName;
            this.enabledBigUnit = await this.$efApi.deptPrivilegeApi.hasDeptPrivilege(
                this.form.deptCode,
                'dept.biz.goods.allow.bigUnit'
            );
            const arr = await StockBizCommon.makeStockBizDetailGoodsAndParams(
                purchaseInBiz,
                this.detailParamPropertyArrForShow()
            );
            this.goodsDetailMap = arr.goodsDetails;
            this.form.detailParams = arr.detailParams;
            const repoDept = await this.$efApi.deptApi.deptDetail(this.form.repoCode);
            this.form.repoName = repoDept.name;
            this.form.customerAddress = repoDept.address;
            const linkman = await this.$efApi.staffApi.staffDetail(repoDept.linkmanCode);
            this.form.customer = linkman.realName;
            this.form.customerMobile = linkman.mobile;
        },
        skuByGoodsCode(goodsCode) {
            return this.goodsDetailMap.get(goodsCode).sku;
        },
        handleDelete(index) {
            this.form.detailParams.splice(index, 1);
        },

        handleSave() {
            this.$refs.createForm.validate(async (valid) => {
                if (!valid) {
                    this.$message.error('填写内容有误，请检查所填写的所有数据');
                    return false;
                }
                const form = this.form;
                const detailParams = form.detailParams;
                const saveParam = {
                    deptCode: form.deptCode,
                    repoCode: form.repoCode,
                    bizCode: form.purchaseApplyCode,
                    customer: form.customer,
                    customerMobile: form.customerMobile,
                    customerAddress: form.customerAddress,
                    goodsCode: detailParams.map((e) => e.goodsCode),
                    counts: detailParams.map((e) => e.counts),
                    giftCounts: detailParams.map((e) => e.giftCounts),
                    packFactors: detailParams.map((e) => e.packFactors),
                    prices: detailParams.map((e) => MoneyUtils.moneyToDb(e.showPrices)),
                };
                UrlUtils.PostRemote(this, this.url.save, saveParam);
            });
        },
    },
};
</script>

<style scoped>
.BatchCreate .el-form-item {
    margin-bottom: 0;
}

.BatchCreate .el-table th.gutter {
    display: table-cell;
}
</style>
